

.searchDomain {
    input {
        width: -webkit-fill-available;
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        border: 1px solid #ddd;
        border-radius: .5rem;
    }
}