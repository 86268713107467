@import './../../variables.scss';

.modal {
    width: 100%;
    max-width: 500px;
   
    overflow: auto;
    border-radius: $borderRadius;
    background-color: white;
    z-index: 10;

    margin: auto;


    > .header {
        padding: 1rem;
        display: grid;
        grid-template-columns: auto min-content;
        align-items: center;
        height: fit-content;

        // position: sticky;
        // top: 0;
        background-color: inherit;

        h4 {
            padding: 0;
            margin: 0;
        }

        button {
            &.close {
                background-color: red;
                padding: $buttonPadding;
                color: white;
                border: none;
                border-radius: $borderRadius;
                cursor: pointer;
            }
        }
    }

    > .body {
        padding: 1rem;
        height: fit-content;
        //max-height: 300px;
        //overflow: auto;
    }

    > .footer {
        padding: 1rem;
    }
}