@import './../../variables.scss';

header {
    .actions {
        .call-to-action {
            padding: .5rem 1.25rem;
            background-color: $brandColor;
            color: $onBrandColor;
            font-size: 1rem;
            border: none;
            border-radius: $borderRadius;
            cursor: pointer;
        }
    }
}