@import "./../../theme.scss";

.side-panel-button {
    padding: 0.75rem .5rem 0.75rem 0.5rem;
    display:grid;
    grid-template-columns: 2.5rem auto;

    border: none;
    background-color: transparent;
    cursor: pointer;

    font-size: 1rem;
    align-items: center;
    transition-duration: $transitionDuration;

    &:hover {
        background-color: $buttonHoverBackground;
    }

    &[is-current=true] {
        background-color: whitesmoke;
    }
   
 
    .text {
        width: 100%;
        text-align: left;
        font-size: inherit;
      
        display: flex;
        align-items: center;
        white-space:nowrap;
        text-overflow: ellipsis;
        

      
    }

    .icon {
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        i, svg {
            font-size: 1rem;
        }
    }



}