@import "./../../../Common/variables.scss";

.add-domain {
  display: flex;
  flex-direction: column;

  label {
    display: block;
    padding: 0.25rem;
  }

  input, select {
    padding: 0.5rem;
    margin: 0;
  }

  .add-new-domain {
    padding: $buttonPadding;
    border-radius: $borderRadius;
    background-color: $brandColor;
    color: $onBrandColor;
    width: 100%;
    border: none;
    cursor: pointer;
  }
}
