@import "./../../../Common/variables.scss";


.domain-table {
  width: 100%;
  //border: 1px solid silver;

  thead {
    border-bottom: 1px solid #ddd;
    text-align: left;
    background-color: whitesmoke;
    th {
        padding: 0.5rem 1rem;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:hover {
        background-color: rgb(246, 246, 246);
      }
      td {
        padding: 0.5rem 1rem;
      }
    }
  }
}


.table-head {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  h4 {
    margin: none;
  }

  button {
    margin: 0;
    padding: 0.75rem 1rem;
    border: none;
    height: fit-content;
    border-radius: 2rem;
  }
}

.domain-title {
  padding: 0.75rem .5rem 0.75rem 0.5rem;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.tablinks {
  padding: 0.75rem 1rem ;
  margin: 0 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: $borderRadius;
  cursor: pointer;

  &[active-tab='1'] {
    background-color: whitesmoke;
  }
}