.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  overflow: auto;
  display: none;
  

  &.show,
  &[visible="true"] {
    display: flex;
    // justify-content: center;
    // align-items: center;
    transition-duration: 300ms;
  }
}
